.regfail-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.failbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;
  height: auto;
  padding: 10px 15px 10px 15px;
  border: 2px solid rgb(208, 76, 76);
  border-radius: 10px;
  background-color: #f1f1f1;
}

@media screen and (max-width: 768px) {
  .failbox {
    width: 80%;
    margin: 5px 15px 5px 15px;
  }
}

.regfail-text {
  line-height: 2rem;
}

.ref-text {
  text-align: center;
}
