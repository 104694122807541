.dashboard-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.success-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  gap: 50px;
  flex-wrap: wrap;
}

.success-logo {
  width: 140px;
  height: auto;
}

.h1-success {
  color: #3a3a39;
}

.main-info {
  text-align: center;
  line-height: 2rem;
}

.booking-info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  max-width: 450px;
  padding: 15px 20px 15px 20px;
  border-radius: 20px;
  box-shadow:
    8px 20px 60px #cdcdcd,
    -20px -20px 60px #f1f1f1;
}

.show-btn {
  width: auto;
  height: auto;
  padding: 5px 10px 5px 10px;
  font-size: 17px;
  font-family: "Raleway", sans-serif;
  border: none;
  border-radius: 2px;
  color: #f1f1f1;
  background-color: #70625988;
  box-shadow:
    2px 2px 10px #70625948,
    -2px -2px 10px #70625978;
  cursor: pointer;
  transition: 300ms ease-in-out;
}

.control-apartment-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
  height: 40px;
  padding: 5px 10px 5px 10px;
  background-color: #82bcee;
  color: #fff;
  border: none;
  border-radius: 3px;
  text-decoration: none;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
  transition: ease-in-out 300ms;
  margin: 10px 0;
  box-shadow:
    5px 5px 20px #b8d2e8,
    -5px -5px 20px #82bcee;
}

.control-apartment-btn:hover,
.control-apartment-btn:focus {
  width: 210px;
  height: 40px;
  padding: 15px 10px 15px 10px;
  font-size: 17px;
  font-family: "Raleway", sans-serif;
  border: none;
  border-radius: 2px;
  color: rgb(255, 255, 255);
  background-color: rgb(169, 210, 246);
  box-shadow:
    2px 2px 10px rgba(221, 244, 252, 0.53),
    -2px -2px 10px rgba(119, 206, 225, 0.53);
  cursor: pointer;
  transition: 300ms ease-in-out;
}

.dashboard-direction-div {
  justify-content: center;
  width: 100%;
  display: flex;
}

.guest-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .guest-data {
    max-width: 300px;
  }
}

.loxone-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
  height: 60px;
  margin: 30px;
  padding: 5px 10px 5px 10px;
  background-color: #69c350;
  color: #fff;
  border: none;
  border-radius: 2px;
  text-decoration: none;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 17px;
  cursor: pointer;
  transition: ease-in-out 300ms;

  box-shadow:
    5px 5px 20px #69c35048,
    -5px -5px 20px #69c35078;
}

.loxone-button:hover,
.loxone-button:focus {
  width: 210px;
  background-color: #80db67;
}

.invoice-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
  height: 60px;
  margin: 30px;
  padding: 5px 10px 5px 10px;
  background-color: #9c0000;
  color: #fff;
  border: none;
  border-radius: 2px;
  text-decoration: none;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 17px;
  cursor: pointer;
  transition: ease-in-out 300ms;

  box-shadow:
    5px 5px 20px #9c000048,
    -5px -5px 20px #9c000078;
}

.invoice-button:hover,
.invoice-button:focus {
  width: 210px;
  background-color: #b02d2d;
}

.direction-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
  height: 40px;
  padding: 5px 10px 5px 10px;
  background-color: #6dcdb1;
  color: #fff;
  border: none;
  border-radius: 3px;
  text-decoration: none;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
  transition: ease-in-out 300ms;
  margin: 20px 0;
  box-shadow:
    5px 5px 20px #6dcdb148,
    -5px -5px 20px #6dcdb178;
}

.direction-btn:hover,
.direction-btn:focus {
  width: 210px;
  background-color: #84dcc1;
}

.dashboard-btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .dashboard-btn-wrap {
    flex-direction: column;
  }
}

.array-span {
  margin-right: 5px;
}

.icons-div {
  max-width: 300px;
  overflow: wrap;
}

.dashboard-guest-data {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  font-size: 17px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  color: #3a3a39;
  gap: 16px;
}

@media screen and (max-width: 768px) {
  .dashboard-guest-data {
    flex-direction: column;
  }
}

.dashboard-guest-numbers{
  display: flex;
  flex-direction: row;
}
.dashboard-guest-data-date-icon{
  font-size: 20px;
  padding-right: 8px;
}

.dashboard-guest-loxone-data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
  font-size: 17px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  color: #3a3a39;
  gap: 25px;
}

.loxone-credentials-grid {
  margin: auto auto;
  width: auto;
  height: auto;
  display: grid;
  grid-template-columns: auto auto;
  grid-row: auto;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.loxone-credentials-copy-button {
  height: 25px;
  position: relative;
  top: 25%;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .success-body {
    justify-content: center;
    flex-direction: column;
    align-items: start;
    gap: 50px;
  }
}

.dashboard-guest-access-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  gap: 8px;
}

.dashboard-guest-access-title {
  font-size: 18px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  color: #3a3a39;
}

.dashboard-guest-access-value {
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  color: #C9910D;
}

.dashboard-guest-access-value.inactive {
  color: #A7A9AB;
}

.dashboard-guest-access-title.center{
  text-align: center;
}

.dashboard-guest-access-title.medium{
  font-size: 16px;
  font-weight: 500;
}

.dashboard-guest-access-valid-badge {
  font-size: 16px;
  font-weight: 600;
  padding: 4px 8px;
  color: #fff;
  border-radius: 4px;
}

.dashboard-guest-access-valid-badge.active {
  background: #6dcdb1;
}

.dashboard-guest-access-valid-badge.inactive {
  background: #A7A9AB;
}
