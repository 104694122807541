.properties-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.property-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.property-icon {
  margin-right: 10px;
}
