.guestmanagement-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .guestmanagement-wrapper {
    align-items: flex-start;
  }
}

.h1-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 50px;
}

@media screen and (max-width: 768px) {
  .h1-div {
    padding-top: 150px;
  }
}

.main-title {
  color: #3a3a3a;
}

table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
  overflow-y: scroll;
}

th {
  background-color: #706259;
  color: #f1f1f1;
}

.freeze-top {
  top: 0;
  position: sticky;
  z-index: 20;
}

th,
td {
  text-align: center;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #70625981;
  color: #f1f1f1;
}

.overview-error {
  color: red;
}

.fetch-button {
  width: 200px;
  height: 40px;
  margin: 20px;
  padding: 10px;
  background-color: #6dcdb1;
  color: #fff;
  border: none;
  font-family: "Raleway", sans-serif;
  font-size: 17px;
  font-weight: 400;
  transition: 300ms;
  text-align: center;
  cursor: pointer;
}

.fetch-button:hover {
  width: 205px;
  background-color: #7bd3b8;
}

.fetch-button:focus {
  width: 205px;
  background-color: #59b499;
}

.button-icon {
  padding-right: 20px;
  width: 20px;
  height: auto;
}

.booking-number {
  text-align: center;
}

.fetch-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sign-out-button {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 150px;
  height: 40px;
  margin: 20px;
  padding: 10px;
  color: #3a3a39;
  border: none;
  font-family: "Raleway", sans-serif;
  font-size: 17px;
  font-weight: 400;
  transition: 300ms;
  text-align: center;
  cursor: pointer;
}

.sign-out-button:hover {
  width: 155px;
  background-color: #676767;
  color: #fff;
}

.sign-out-button:focus {
  width: 155px;
  background-color: #434242;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .sign-out-button {
    display: none;
  }
}

.sign-out-button-mini {
  position: absolute;
  z-index: 20;
  top: 20px;
  right: 20px;
  text-align: center;
  width: 70px;
  height: 70px;
  margin: 20px;
  padding: 10px;
  color: #3a3a39;
  border: none;
  border-radius: 50%;
  font-family: "Raleway", sans-serif;
  font-size: 25px;
  font-weight: 400;
  transition: 300ms;
  cursor: pointer;
}

.sign-out-button-mini:hover {
  position: absolute;
  top: 52.5px;
  right: 22.5px;
  height: 65px;
  width: 65px;
  background-color: #676767;
  color: #fff;
}

.sign-out-button-mini:focus {
  position: absolute;
  top: 52.5px;
  right: 22.5px;
  height: 65px;
  width: 65px;
  background-color: #434242;
  color: #fff;
}

.loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top of other content */
}

.loading-text {
  font-size: 24px;
  color: #333;
}

@media screen and (min-width: 768px) {
  .sign-out-button-mini {
    display: none;
  }
}
