.collapsible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.control-apartment-collapsible {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputField-email {
  width: 640px;
  height: 45px;
  padding: 0px 10px 0px 10px;
  font-size: 17px;
  font-family: "Raleway", sans-serif;
  border: none;
  border-radius: 1px;
  color: #3a3a39 !important;
  border-bottom: 1px solid #3a3a39;
  background-color: #fff;
  transition: ease-in-out 300ms;
  box-shadow:
    20px 20px 60px #cdcdcd,
    -20px -20px 60px #f1f1f1;
}

.inputField-email:hover {
  background-color: #f9f9f9;
  border-bottom: 1px solid #6dcdb1;
}

.inputField-email:focus {
  background-color: #f9f9f9;
  border-bottom: 1px solid #6dcdb1;
}

@media (max-width: 720px) {
  .inputField-email {
    flex-direction: column;
    align-items: stretch;
    width: 300px;
  }
}

.dropDown {
  width: 320px;
  height: 45px;
  padding: 0px 10px 0px 10px;
  border: none;
  border-bottom: 1px solid #3a3a39;
  color: #3a3a39;
  background-color: #fff;
  transition: ease-in-out 300ms;
  font-size: 17px;
  font-family: "Raleway", sans-serif;
  box-shadow:
    20px 20px 60px #cdcdcd,
    -20px -20px 60px #f1f1f1;
}

.dropDown:hover {
  background-color: #f9f9f9;
  border-bottom: 1px solid #6dcdb1;
}

.dropDown:focus {
  background-color: #f9f9f9;
  border-bottom: 1px solid #6dcdb1;
}

.title {
  width: 75px;
  height: 30px;
  font-family: "Raleway", sans-serif;
  border: none;
  border-bottom: 1px solid #3a3a39;
  background-color: #fff;
  transition: ease-in-out 300ms;
}

.title:hover {
  background-color: #f9f9f9;
  border-bottom: 1px solid #6dcdb1;
}

.title:focus {
  background-color: #f9f9f9;
  border-bottom: 1px solid #6dcdb1;
}

.inputField {
  width: 300px;
  height: 45px;
  padding: 0px 10px 0px 10px;
  font-size: 17px;
  font-family: "Raleway", sans-serif;
  border: none;
  border-radius: 1px;
  color: #3a3a39 !important;
  border-bottom: 1px solid #3a3a39;
  background-color: #fff;
  transition: ease-in-out 300ms;
  box-shadow:
    20px 20px 60px #cdcdcd,
    -20px -20px 60px #f1f1f1;
}

.inputField:hover {
  background-color: #f9f9f9;
  border-bottom: 1px solid #6dcdb1;
}

.inputField:focus {
  background-color: #f9f9f9;
  border-bottom: 1px solid #6dcdb1;
}

.inline {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  text-align: left !important;
  color: #3a3a39 !important;
}

@media (max-width: 720px) {
  .inline {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.form-collapsed-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 70px;
  margin-top: 30px;
  margin-bottom: -10px;
  padding: 10px;
  border-radius: 10px;
  color: #f1f1f1;
  background-color: #6dcdb1;
  box-shadow:
    5px 5px 20px #6dcdb148,
    -5px -5px 20px #6dcdb178;
  transition: 500ms ease-in-out;
}

.form-collapsed-background:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  color: #6dcdb1;
  background-color: #f1f1f1;
  box-shadow:
    5px 5px 20px #cdcdcd,
    -5px -5px 20px #f1f1f1;
}

.control-apartment-collapsed-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  margin-top: 20px;
  border-radius: 3px;
  color: #f1f1f1;
  text-decoration: none;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  background-color: #6dcdb1;
  box-shadow:
    5px 5px 20px #6dcdb148,
    -5px -5px 20px #6dcdb178;
  transition: 500ms ease-in-out;
}

.control-apartment-collapsed-background:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 40px;
  border-radius: 3px;
  color: #6dcdb1;
  background-color: #f1f1f1;
  box-shadow:
    5px 5px 20px #cdcdcd,
    -5px -5px 20px #f1f1f1;
}

.form-collapsed-background:focus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 70px;
  padding: 10px;
  border-radius: 10px;
  color: #6dcdb1;
  border: 2px solid #6dcdb1;
  background-color: #f1f1f1;
  box-shadow:
    5px 5px 20px #cdcdcd,
    -5px -5px 20px #f1f1f1;
}

.inputField-familyMember-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

input[type="text"]:disabled {
  background: #dddddd;
}

.propertyForm-invalid{
  border: 1px solid red !important;
}

.entry-code-collapse {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease, visibility 0.5s ease;
}

.entry-code-collapse.isExpanded {
  max-height: 1500px;
  opacity: 1;
  visibility: visible;
}

.entry-code-section{
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 36px;
}

.entry-code-border {
  height: 1px;
  width: 100%;
  background: gray;
}

.entry-code-warning{
  color: darkorange;
  text-align: center;
}

.entry-code-access-name{
  font-weight: bold;
}

.entry-code-lock-wrapper{
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.dashboard-guest-checkin-checkout-time{
 margin-top: 20px;
}
