#root {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
}

.logo {
  width: 200px;
  height: auto;
}

.regformWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  color: #3a3a39;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
}

.header-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  width: 80vw;
  font-size: 17px;
  line-height: 2rem;
  text-align: center;
}

.regpage-welcome {
  margin-top: 6px;
  margin-bottom: 0;
}

.secondary-info {
  color: #b3b3b3;
}

.inline-data {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .inline-data {
    flex-direction: column; /* Stack items vertically on mobile */
  }
}

.property-icon-name{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.property-guests{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 300px;
  margin-bottom: 15px;
}

.icon-background {
  padding: 10px;
  border-radius: 10px;
  color: #f1f1f1;
  background-color: #3a3a3930;
  box-shadow:
    5px 5px 20px #3a3a3910,
    -5px -5px 20px #3a3a3920;
}

.icon-background-step2-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 10px;
  padding: 10px;
  border-radius: 5px;
  font-weight: 700;
  color: #f1f1f1;
  background-color: #6dcdb1;
  box-shadow:
    5px 5px 20px #6dcdb148,
    -5px -5px 20px #6dcdb178;
}

.icon-background-step2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 2px solid #6dcdb1;
  border-radius: 10px;
  color: #f1f1f1;
  background-color: none;
  box-shadow:
    5px 5px 20px #6dcdb148,
    -5px -5px 20px #6dcdb178;
}

.property-card {
  width: 250px;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.h5-reg-info {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  gap: 20px;
  margin: 0 15px 0 0;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.privacy-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.apartment-rules-p {
  margin: 0px;
  line-height: 2rem;
}

.inline-link {
  background-color: #fff;
  padding: 5px;
  border-bottom: 1px solid #6dcdb1;
  text-decoration: none;
  color: #706259;
}

@media screen and (max-width: 768px) {
  .inline-link {
    line-height: 20px;
  }
}

.button-icon-send {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.submitButton {
  width: 200px;
  height: 60px;
  margin: 30px;
  background-color: #6dcdb1;
  color: #fff;
  border: none;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  border-radius: 8px;
  transition: ease-in-out 300ms;
  box-shadow:
    5px 5px 20px #6dcdb148,
    -5px -5px 20px #6dcdb178;
}

.submitButton:hover {
  width: 210px;
  background-color: #84dcc1;
}

.submitButton:focus {
  width: 210px;
  background-color: #259e7a;
}

.main-title {
  padding-bottom: 5px;
  border-bottom: 2px solid #6dcdb1;
  font-size: 22px;
  font-weight: 700;
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .main-title {
    margin-top: 20px;
  }
}

.main-info {
  font-size: 18px;
  margin-left: 10px;
}

.booking-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.guestnumber-details {
  margin-left: 10px;
}

input::placeholder {
  color: #3a3a39;
}
.submit-loading{
  margin-left: 4px;
}

.submitButton:disabled {
  cursor: default;
  background: #bbb;
  box-shadow: none;
}

.submitButton:disabled:hover {
  width: 200px;
}


.hyper-link-guest-dashboard {
  margin-top: 20px;
  text-align: center;
}
