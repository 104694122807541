body {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f1f1f1;
  color: #3a3a39;
  font-family: "Raleway", sans-serif;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  width: 100vw;
  z-index: 5;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
}

.menu-bar {
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #706259;
  padding: 10px;
}

@media screen and (max-width: 850px) {
  .menu-bar {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
}

h1 {
  display: flex;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #f1f1f1;
  padding: 10px;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
}

h2 {
  display: flex;
  justify-content: center;
  font-family: "Raleway", sans-serif;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  color: #3a3a39;
  margin-top: 30px;
  padding: 10px;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
}

.header-navigation {
  display: none;
  width: auto;
  height: 50px;
  border-bottom: 3px solid #706259;
  z-index: 3;

  background-color: #706259;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 20px;
  color: #f1f1f1;
  letter-spacing: 0.1em;

  transition: ease-in-out 300ms;
}

.header-navigation:hover {
  background-color: #706259bc;
  border-bottom: 3px solid #6dcdb1;
}

.language-selector-wrapper {
  display: none !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
  margin-bottom: -50px;
  padding: 10px;
  width: 100vw;
  height: 100px;
  position: absolute;
  top: 20px;
  right: 10px;
}

.language-selector {
  width: 100px;
  height: 30px;
  margin-right: 10px;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.1em;
  background-color: #3a3a39;
  opacity: 80%;
  color: #f1f1f1;
  border-radius: 3px;

  transition: 300ms ease-in-out;

  border: none;
}

.language-selector:hover {
  opacity: 100%;
  border: 1px solid #6dcdb1;
}

.language-selector:active {
  opacity: 100%;
  border: 1px solid #6dcdb1;
}
