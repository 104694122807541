.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.login-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  padding: 10px;
  background: #f1f1f1;
  box-shadow:
    20px 20px 60px #cccccc,
    -20px -20px 60px #dddddd;
  height: 50vh;
  width: 80vw;
}

.logo-login {
  height: auto;
  width: 150px;
  padding: 10px;
  border-radius: 10px;
}

.login-h2 {
  text-align: center;
}

.sign-in-button {
  width: 240px;
  height: 40px;
  margin: 20px;
  padding: 10px;
  background-color: #6dcdb1;
  color: #fff;
  border: none;
  font-family: "Raleway", sans-serif;
  font-size: 17px;
  font-weight: 400;
  transition: 300ms;
  text-align: center;
  cursor: pointer;
}

.sign-in-button:hover {
  width: 245px;
  background-color: #7bd3b8;
}

.sign-in-button:focus {
  width: 245px;
  background-color: #59b499;
}

.button-icon {
  padding-right: 20px;
  width: 20px;
  height: auto;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 40px;
}
