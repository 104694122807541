.diff-invoice-text {
  font-size: 17px;
  font-weight: 500;
}

input[type="checkbox"] {
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
}

input[type="checkbox"]::before {
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

.different-invoice-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #3a3a39;
  padding: 9px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #e3e3e3ab;
  background: white;
  margin-top: 30px;
}

.invoice-address-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.propertyForm-invalid{
  border: 1px solid red !important;
}
